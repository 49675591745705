import { RetellWebClient } from "retell-client-js-sdk";

import { useCallback, useEffect, useState } from "react";

import { logger } from "interfaces/logger";

import { FrameShape } from "components/shared/library/Avatar";

import { useRealtimeChannelHealth } from "utils/realtime";

import { fetchAuthWrapper } from "./backend";
import { CatalogPrice, getCatalogPricing } from "./ribbonPro";
import { supabase } from "./supabaseClient";
import { UserInfo } from "./user";

export type InterviewFlowStatus = "open" | "closed" | "archived";
export type CandidateStatus = "pending-review" | "accepted" | "rejected";

export type InterviewFlow = {
  id: string;
  roleTitle: string;
  status: InterviewFlowStatus;
  createdAt: string;
  numCandidates: number;
  numNeedsReview: number;
};

export type InterviewFlowDb = {
  id: string;
  role_title: string;
  status: InterviewFlowStatus;
  created_at: string;
  num_candidates: number;
  num_need_reviews: number;
};

type Candidate = Pick<
  UserInfo,
  "id" | "firstName" | "lastName" | "avatarUrl" | "shape" | "handle" | "email"
>;

export type InterviewCall = {
  id: string;
  recordingUrl: string;
  filters: { questionId: string; answer: string }[];
  endedAt: string;
  candidateStatus: CandidateStatus;
  overallScore?: number;
  candidate: Candidate;
  numOfDocuments: number;
};

export type InterviewOverview = {
  active: number;
  totalScreened: number;
};

type InterviewOverviewDb = {
  active: number;
  total_screened: number;
};

export type InterviewCallDb = {
  retell_call_id: string;
  recording_url: string;
  filters: { question_id: string; answer: string }[];
  ended_at: string;
  candidate_status: CandidateStatus;
  overall_score: number;
  candidate_id: string;
  candidate_first_name: string;
  candidate_last_name: string;
  candidate_photo_url: string;
  candidate_shape: string;
  candidate_handle: string;
  candidate_email: string;
  num_of_documents: number;
};
export type InterviewDetails = {
  id: string;
  retellAgentId?: string;
  voiceId: string;
  language: string;
  orgName: string;
  roleTitle: string;
  location?: string;
  jobDescription?: string;
  status: InterviewFlowStatus;
  createdAt: string;
  questions: {
    question: string;
    possibleAnswers?: string[];
  }[];
  atsStageId?: string;
  atsJobId?: string;
  successfulInterviewCalls: number;
  isRibbonSourcingEnabled: boolean;
  weeklyBudgetInCents?: number;
  hiringManager?: string;
  companyLogo?: string;
  additionalInfo?: string;
  isVideoEnabled: boolean;
};

export type InterviewDetailsDb = {
  id: string;
  retell_agent_id?: string;
  retell_voice_id: string;
  language: string;
  org_name: string;
  role_title: string;
  location?: string;
  job_description?: string;
  status: InterviewFlowStatus;
  created_at: string;
  questions: {
    question: string;
    possible_answers: string[];
  }[];
  ats_integration_job_id: string;
  ats_integration_stage_id_on_call_success: string;
  successful_interview_calls: number;
  is_ribbon_sourcing_enabled: boolean;
  weekly_budget_in_cents?: number;
  hiring_manager?: string;
  company_logo?: string;
  additional_info?: string;
  is_video_enabled: boolean;
};

type InterviewFlowQuestionDb = {
  id: string;
  interview_flow_id: string;
  question: string;
  possible_answers: string[];
  created_at: string;
};
export type InterviewFlowQuestion = {
  id: string;
  interviewFlowId: string;
  question: string;
  possibleAnswers: string[];
  createdAt: string;
};

export type Word = {
  word: string;
  start: number;
  end: number;
};

export type Segment = {
  content: string;
  role: "agent" | "user";
  words: Word[];
};

export type Transcript = Segment[];

type DbCandidateInterviewDetail = {
  candidate_id: string;
  candidate_status: string;
  candidate_handle: string;
  candidate_first_name: string;
  candidate_last_name: string;
  candidate_photo_url: string;
  candidate_shape: string;
  candidate_email: string;
  call_id: string;
  transcript: string;
  summary: string;
  background?: string[];
  motivation_and_interest?: string[];
  logistical_considerations?: string[];
  tools_and_skills?: string[];
  recording_url: string;
  video_playback_url?: string;
  called_at: string;
  ended_at: string;
  filters: string;
  score_communication: number;
  score_motivation: number;
  score_skills: number;
  created_at: string;
  transcript_with_timestamp: Transcript;
  wrapped_url?: string;
  follow_up_questions?: string[];
  org_name: string;
};

export type CandidateInterviewDetail = {
  candidateStatus: string;
  candidate: Candidate;
  callId: string;
  transcript: string;
  summary: string;
  background?: string[];
  motivationAndInterest?: string[];
  logisticalConsiderations?: string[];
  toolsAndSkills?: string[];
  recordingUrl: string;
  videoPlaybackUrl?: string;
  calledAt: string;
  endedAt: string;
  filters: string;
  scoreCommunication: number;
  scoreMotivation: number;
  scoreSkills: number;
  createdAt: string;
  transcriptWithTimeStamp?: Transcript;
  wrappedUrl?: string;
  followUpQuestions?: string[];
  orgName: string;
};

export type InterviewJobInfo = Pick<
  InterviewDetails,
  | "companyLogo"
  | "jobDescription"
  | "roleTitle"
  | "orgName"
  | "location"
  | "isVideoEnabled"
>;

export const getClientSecret = async (
  stripePriceId: string
): Promise<string | undefined> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/create-subscription-setup-intent",
    { priceId: stripePriceId }
  );

  if (res.status !== 200) {
    logger("Error create-subscription-setup-intent", "error");
  }

  const { body } = await res.json();

  return body.client_secret;
};

export const getInterviewCallId = async (
  interviewFlowId: string
): Promise<
  | {
      error: undefined;
      callId: string;
      phoneNumber?: string;
      accessToken: string;
    }
  | {
      error: string;
      callId: undefined;
      phoneNumber: undefined;
      accessToken: undefined;
    }
> => {
  const res = await fetchAuthWrapper.post("/be-api/get-interview-call-id", {
    interviewFlowId,
  });

  if (res.status === 504) {
    // call it again if we hit a timeout
    return getInterviewCallId(interviewFlowId);
  }

  if (res.status !== 200) {
    logger("Error get-interview-call-id", "error");
    return {
      error: "Something unexpected happened",
      callId: undefined,
      phoneNumber: undefined,
      accessToken: undefined,
    };
  }

  const { body } = await res.json();

  return {
    error: undefined,
    callId: body.retell_call_id,
    phoneNumber: body.phone_number,
    accessToken: body.access_token,
  };
};

export const getInterviewJobInfo = async (
  interviewFlowId: string
): Promise<InterviewJobInfo> => {
  const { data, error } = await supabase.rpc("get_interview_job_info", {
    p_interview_flow_id: interviewFlowId,
  });
  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  if (!data.length) {
    logger(`No interview found with id [${interviewFlowId}]`, "error");
    throw new Error(`No interview found with id [${interviewFlowId}]`);
  }

  return {
    companyLogo: data[0].company_logo,
    jobDescription: data[0].job_description,
    roleTitle: data[0].role_title,
    orgName: data[0].org_name,
    location: data[0].location,
    isVideoEnabled: data[0].is_video_enabled,
  };
};

export const getInterviewPhoneNumber = async (
  interviewFlowId: string
): Promise<{ phoneNumber?: string; error?: string } | undefined> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/get-interview-phone-number",
    {
      interviewFlowId,
    }
  );

  if (res.status !== 200) {
    logger("Error get-interview-phone-number", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { phoneNumber: body.phone_number };
};

export const shareFilesToInterview = async ({
  userId,
  interviewId,
  selectedFiles,
}: {
  userId: string;
  interviewId: string;
  selectedFiles: string[];
}) => {
  // delete all existing
  const { error: deleteError } = await supabase
    .from("interview_flow_user_document")
    .delete()
    .eq("interview_flow_id", interviewId)
    .eq("candidate_id", userId);

  if (deleteError) {
    throw new Error(deleteError.message);
  }
  // add the newly selected
  const { error } = await supabase.from("interview_flow_user_document").insert(
    selectedFiles.map((filename) => ({
      interview_flow_id: interviewId,
      candidate_id: userId,
      filename,
    }))
  );

  if (error) {
    throw new Error(error.message);
  }
};

// Initialize the SDK
const retellClient = new RetellWebClient();
export const useCallRecruiAIAgent = () => {
  const [status, setStatus] = useState<"not-started" | "ongoing" | "ended">(
    "not-started"
  );
  const [isAgentTalking, setIsAgentTalking] = useState(false);

  useEffect(() => {
    retellClient.on("call_started", () => setStatus("ongoing"));
    retellClient.on("call_ended", () => setStatus("ended"));
    retellClient.on("agent_start_talking", () => setIsAgentTalking(true));
    retellClient.on("agent_stop_talking", () => setIsAgentTalking(false));
    retellClient.on("error", (error) => {
      logger(`Recruit AI error: ${error}`, "error");
    });
  }, []);

  const startCall = useCallback(
    (accessToken: string, stream?: MediaStream, sinkId?: string) =>
      retellClient.startCall({
        accessToken: accessToken,
        sampleRate: 24000,
        captureDeviceId: stream?.getAudioTracks()[0].getSettings().deviceId,
        playbackDeviceId: sinkId === "default" ? undefined : sinkId,
      }),

    []
  );
  const endCall = useCallback(() => retellClient.stopCall(), []);

  return { startCall, endCall, callStatus: status, isAgentTalking };
};

export const createInterview = async ({
  orgName,
  roleTitle,
  questions,
  jobDescription,
  location,
  atsJobId,
  atsStageId,
  voiceId,
  language,
  weeklyBudgetInCents,
  isRibbonSourcingEnabled,
  hiringManager,
  additionalInfo,
  companyLogo,
  isVideoEnabled,
}: {
  orgName: string;
  roleTitle: string;
  questions: string[];
  jobDescription?: string;
  location?: string;
  atsJobId?: string;
  atsStageId?: string;
  voiceId: string;
  language: string;
  weeklyBudgetInCents?: number;
  isRibbonSourcingEnabled?: boolean;
  hiringManager?: string;
  additionalInfo?: string;
  companyLogo?: string;
  isVideoEnabled: boolean;
}): Promise<{ interviewFlowId?: string; error?: string }> => {
  const res = await fetchAuthWrapper.post("/be-api/create-interview-flow", {
    orgName,
    roleTitle,
    location,
    jobDescription,
    questions,
    atsJobId,
    atsStageId,
    voiceId,
    language,
    weeklyBudgetInCents,
    isRibbonSourcingEnabled,
    hiringManager,
    additionalInfo,
    companyLogo,
    isVideoEnabled,
  });

  if (res.status !== 200) {
    logger("Error creating recruit AI interview", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { interviewFlowId: body.interview_flow_id };
};

export const updateInterview = async ({
  interviewFlowId,
  orgName,
  roleTitle,
  questions,
  jobDescription,
  location,
  atsJobId,
  atsStageId,
  voiceId,
  language,
  weeklyBudgetInCents,
  isRibbonSourcingEnabled,
  hiringManager,
  additionalInfo,
  companyLogo,
  isVideoEnabled,
}: {
  interviewFlowId: string;
  orgName: string;
  roleTitle: string;
  questions: string[];
  jobDescription?: string;
  location?: string;
  atsJobId?: string;
  atsStageId?: string;
  voiceId: string;
  language: string;
  weeklyBudgetInCents?: number;
  isRibbonSourcingEnabled?: boolean;
  hiringManager?: string;
  additionalInfo?: string;
  companyLogo?: string;
  isVideoEnabled: boolean;
}): Promise<{ interviewFlowId?: string; error?: string }> => {
  const res = await fetchAuthWrapper.post("/be-api/update-interview-flow", {
    interviewFlowId,
    orgName,
    roleTitle,
    location,
    jobDescription,
    questions,
    atsJobId,
    atsStageId,
    voiceId,
    language,
    weeklyBudgetInCents,
    isRibbonSourcingEnabled,
    hiringManager,
    additionalInfo,
    companyLogo,
    isVideoEnabled,
  });

  if (res.status !== 200) {
    logger("Error updating recruit AI interview", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { interviewFlowId: body.interview_flow_id };
};

const formatDbInterview = (data: InterviewFlowDb[]): InterviewFlow[] => {
  return data.map((interview: InterviewFlowDb) => ({
    id: interview.id,
    roleTitle: interview.role_title,
    status: interview.status,
    createdAt: interview.created_at,
    numCandidates: interview.num_candidates,
    numNeedsReview: interview.num_need_reviews,
  }));
};

const getYourInterviews = async (
  pageNumber: number,
  pageSize: number
): Promise<InterviewFlow[]> => {
  const { data, error } = await supabase.rpc("get_your_interviews", {
    page_size: pageSize,
    page_number: pageNumber,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return formatDbInterview(data);
};

export const useYourInterviews = () => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [interviews, setInterviews] = useState<InterviewFlow[]>([]);
  const pageSize = 3;

  const loadMoreInterviews = useCallback(async () => {
    setLoadingMore(false);
    const data = await getYourInterviews(pageNumber + 1, pageSize);
    setPageNumber(pageNumber + 1);
    setInterviews((prevState) => [...prevState, ...data]);
    setLoadingMore(true);
    if (data.length < pageSize) {
      setIsFinalPage(true);
      return;
    }
    setIsFinalPage(false);
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber > 0) return;
    const loadMore = async () => {
      await loadMoreInterviews();
      setIsLoaded(true);
    };
    loadMore();
  }, [loadMoreInterviews, pageNumber]);

  return {
    interviews,
    isFinalPage,
    loadMoreInterviews,
    loadingMore,
    isLoaded,
  };
};

const formatInterviewCallDb = (data: InterviewCallDb[]): InterviewCall[] => {
  return data.map((interviewCall) => ({
    id: interviewCall.retell_call_id,
    recordingUrl: interviewCall.recording_url,
    filters: interviewCall.filters?.map((filter) => ({
      questionId: filter.question_id,
      answer: filter.answer,
    })),
    endedAt: interviewCall.ended_at,
    candidateStatus: interviewCall.candidate_status,
    overallScore: interviewCall.overall_score,
    candidate: {
      id: interviewCall.candidate_id,
      firstName: interviewCall.candidate_first_name,
      lastName: interviewCall.candidate_last_name,
      avatarUrl: interviewCall.candidate_photo_url,
      shape: interviewCall.candidate_shape as FrameShape,
      handle: interviewCall.candidate_handle,
      email: interviewCall.candidate_email,
    },
    numOfDocuments: interviewCall.num_of_documents,
  }));
};

export const getCandidateInterviewsOverviews = async (
  interviewFlowId: string
): Promise<InterviewCall[]> => {
  const { data, error } = await supabase.rpc(
    "get_candidate_interviews_overviews",
    {
      p_interview_flow_id: interviewFlowId,
    }
  );

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return formatInterviewCallDb(data);
};

type CallLoaded = { calls: InterviewCall[]; isLoaded: true };
type CallLoading = { calls: undefined; isLoaded: false };
export const useCandidateInterviewsOverviewsRealtime = (
  interviewFlowId: string
): CallLoaded | CallLoading => {
  const [calls, setCalls] = useState<InterviewCall[]>();

  const interviewCallChannelName = "db-interview-call";
  const isChannelHealthy = useRealtimeChannelHealth(
    interviewCallChannelName,
    !!interviewFlowId
  );

  useEffect(() => {
    // only sub if we have no interview_calls
    if (!interviewFlowId || typeof calls != "undefined") return;

    const channel = supabase.channel(interviewCallChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "interview_call",
        filter: `interview_flow_id=eq.${interviewFlowId}`,
      },
      async () =>
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId))
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "interview_call",
        filter: `interview_flow_id=eq.${interviewFlowId}`,
      },
      async () =>
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId))
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId));
      }
    });
  }, [interviewFlowId, isChannelHealthy, calls]);

  // we check that the value is a undefined, since [] is falsy in javascript
  if (typeof calls !== "undefined") {
    return { calls, isLoaded: true };
  } else {
    return { calls: undefined, isLoaded: false };
  }
};

const formatDbInterviewDetails = (
  data: InterviewDetailsDb
): InterviewDetails => {
  return {
    id: data.id,
    retellAgentId: data.retell_agent_id,
    voiceId: data.retell_voice_id,
    language: data.language,
    orgName: data.org_name,
    roleTitle: data.role_title,
    location: data.location,
    jobDescription: data.job_description,
    status: data.status,
    createdAt: data.created_at,
    questions: data.questions.map((q) => ({
      question: q.question,
      possibleAnswers: q.possible_answers,
    })),
    atsJobId: data.ats_integration_job_id,
    atsStageId: data.ats_integration_stage_id_on_call_success,
    successfulInterviewCalls: data.successful_interview_calls,
    isRibbonSourcingEnabled: data.is_ribbon_sourcing_enabled,
    weeklyBudgetInCents: data.weekly_budget_in_cents,
    hiringManager: data.hiring_manager,
    companyLogo: data.company_logo,
    additionalInfo: data.additional_info,
    isVideoEnabled: data.is_video_enabled,
  };
};

export const getInterviewDetails = async (
  interviewFlowId: string
): Promise<{
  error?: string;
  interviewDetail?: InterviewDetails;
}> => {
  const { data, error } = await supabase.rpc("get_interview_details", {
    interview_id: interviewFlowId,
  });

  if (error || !data.length) {
    const message = error?.message || "No data found";
    logger(message, "error");
    return { error: message };
  }

  return { interviewDetail: formatDbInterviewDetails(data[0]) };
};

const formatDbCandidateInterviewDetail = (
  data: DbCandidateInterviewDetail[]
): CandidateInterviewDetail[] => {
  return data.map((candidateInterviewDetail) => ({
    candidateStatus: candidateInterviewDetail.candidate_status,
    candidate: {
      id: candidateInterviewDetail.candidate_id,
      firstName: candidateInterviewDetail.candidate_first_name,
      lastName: candidateInterviewDetail.candidate_last_name,
      avatarUrl: candidateInterviewDetail.candidate_photo_url,
      shape: candidateInterviewDetail.candidate_shape as FrameShape,
      handle: candidateInterviewDetail.candidate_handle,
      email: candidateInterviewDetail.candidate_email,
    },
    callId: candidateInterviewDetail.call_id,
    transcript: candidateInterviewDetail.transcript,
    summary: candidateInterviewDetail.summary,
    background: candidateInterviewDetail.background,
    motivationAndInterest: candidateInterviewDetail.motivation_and_interest,
    logisticalConsiderations:
      candidateInterviewDetail.logistical_considerations,
    toolsAndSkills: candidateInterviewDetail.tools_and_skills,
    recordingUrl: candidateInterviewDetail.recording_url,
    videoPlaybackUrl: candidateInterviewDetail.video_playback_url,
    calledAt: candidateInterviewDetail.called_at,
    endedAt: candidateInterviewDetail.ended_at,
    filters: candidateInterviewDetail.filters,
    scoreCommunication: candidateInterviewDetail.score_communication,
    scoreMotivation: candidateInterviewDetail.score_motivation,
    scoreSkills: candidateInterviewDetail.score_skills,
    createdAt: candidateInterviewDetail.created_at,
    transcriptWithTimeStamp:
      candidateInterviewDetail.transcript_with_timestamp?.filter(
        (segment) => segment.words.length > 0
      ),
    wrappedUrl: candidateInterviewDetail.wrapped_url,
    followUpQuestions: candidateInterviewDetail.follow_up_questions,
    orgName: candidateInterviewDetail.org_name,
  }));
};

// NOTE: this is plural but right now only returns one set of details
// in the near future we will support multiple calls per candidate<->interview
export const getCandidateInterviewDetails = async ({
  interviewFlowId,
  candidateId,
}: {
  interviewFlowId: string;
  candidateId: string;
}): Promise<{
  error?: string;
  candidateInterviewDetail?: CandidateInterviewDetail;
}> => {
  const { data, error } = await supabase.rpc(
    "get_candidate_interview_details",
    {
      p_interview_flow_id: interviewFlowId,
      p_candidate_id: candidateId,
    }
  );

  if (error || !data.length) {
    const message = error?.message || "No data found";
    logger(message, "error");
    return { error: message };
  }

  return {
    candidateInterviewDetail: formatDbCandidateInterviewDetail(data)[0],
  };
};

export const updateInterviewFlowStatus = async (
  interviewFlowId: string,
  status: InterviewFlowStatus
) => {
  const { error } = await supabase
    .from("interview_flow")
    .update({ status: status })
    .eq("id", interviewFlowId);
  return { error: error?.message };
};

export const updateCandidateStatus = async (
  interviewFlowId: string,
  candidateId: string,
  status: CandidateStatus
) => {
  const { error } = await supabase
    .from("interview_call")
    .update({ candidate_status: status })
    .eq("interview_flow_id", interviewFlowId)
    .eq("candidate_id", candidateId);

  if (error) {
    logger(error.message, "error");
    return { error: "Something went wrong when updating the candidate status" };
  }
  return {};
};

export const findSharedFilenames = async (
  candidateId: string,
  interviewId: string
): Promise<string[]> => {
  const { data, error } = await supabase
    .from("interview_flow_user_document")
    .select("filename")
    .eq("candidate_id", candidateId)
    .eq("interview_flow_id", interviewId);

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  return data.map((d) => d.filename);
};

const formatInterviewFlowQuestionDb = (
  question: InterviewFlowQuestionDb
): InterviewFlowQuestion => ({
  id: question.id,
  interviewFlowId: question.interview_flow_id,
  question: question.question,
  possibleAnswers: question.possible_answers,
  createdAt: question.created_at,
});

export const getFilteringQuestions = async (interviewFlowId: string) => {
  const { error, data } = await supabase
    .from("interview_flow_question")
    .select("*")
    .eq("interview_flow_id", interviewFlowId)
    .not("possible_answers", "is", null);

  if (error || !data) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((question) =>
    formatInterviewFlowQuestionDb(question as InterviewFlowQuestionDb)
  );
};

type QuestionsLoaded = { questions: InterviewFlowQuestion[]; isLoaded: true };
type QuestionsLoading = { questions: undefined; isLoaded: false };
export const useFilteringQuestions = (
  interviewFlowId: string
): QuestionsLoaded | QuestionsLoading => {
  const [questions, setQuestions] = useState<InterviewFlowQuestion[]>();

  useEffect(() => {
    const get = async () => {
      setQuestions(await getFilteringQuestions(interviewFlowId));
    };
    get();
  }, [interviewFlowId]);

  if (typeof questions === "undefined") {
    return { questions: undefined, isLoaded: false };
  } else {
    return { questions, isLoaded: true };
  }
};

type recruitPricingLoaded = { price: CatalogPrice; isLoaded: true };
type recruitPricingLoading = { price: undefined; isLoaded: false };
export const useRecruitPricing = ():
  | recruitPricingLoaded
  | recruitPricingLoading => {
  const [price, setPrice] = useState<CatalogPrice>();

  useEffect(() => {
    const get = async () => {
      const prices = (await getCatalogPricing())?.catalogPrices;
      setPrice(prices?.find((p) => p.productName === "interview-usage"));
    };
    get();
  }, []);

  if (typeof price === "undefined") {
    return { price: undefined, isLoaded: false };
  } else {
    return { price, isLoaded: true };
  }
};

const formatInterviewOverview = (
  data: InterviewOverviewDb
): InterviewOverview => {
  return {
    active: data.active,
    totalScreened: data.total_screened,
  };
};

export const getInterviewOverview = async (): Promise<InterviewOverview> => {
  const { data, error } = await supabase.rpc("get_interview_overview");

  if (error) {
    logger(error.message, "error");
  }

  return formatInterviewOverview(data[0]);
};

export const getIsInterviewOpen = async ({
  interviewFlowId,
}: {
  interviewFlowId: string;
}): Promise<{
  isInterviewOpen?: boolean;
  error?: string;
}> => {
  const { data, error } = await supabase
    .from("interview_flow")
    .select("status")
    .eq("id", interviewFlowId);

  if (error) {
    logger(error.message, "error");
    return { error: error.message };
  }

  if (!data || data.length === 0) {
    return { error: "Interview not found" };
  }

  return { isInterviewOpen: data[0].status === "open" };
};

export const findInterviewWrapped = async (
  interviewCallId: string
): Promise<string | undefined> => {
  const { data, error } = await supabase.rpc("find_interview_wrapped", {
    interview_call_id: interviewCallId,
  });

  if (error) {
    logger(error.message, "error");
  }

  return data?.[0];
};

const getInterviewCallCount = async (
  teamId: string
): Promise<number | undefined> => {
  const { data, error } = await supabase
    .from("interview_call")
    .select("*")
    .eq("team_id", teamId)
    .eq("processing_status", "success");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.length;
};

type TotalCallsLoaded = { count: number; isLoaded: true };
type TotalCallsLoading = { count: undefined; isLoaded: false };
export const useTotalInterviewCallsRealtime = (
  teamId?: string
): TotalCallsLoaded | TotalCallsLoading => {
  const [count, setCount] = useState<number>();

  const interviewCallsCountChannelName = "db-interview-calls-count";
  const isChannelHealthy = useRealtimeChannelHealth(
    interviewCallsCountChannelName,
    !!teamId
  );

  useEffect(() => {
    // reset count on team change
    setCount(undefined);
  }, [teamId]);

  useEffect(() => {
    // only sub if we have no count yet
    if (!teamId || typeof count != "undefined") return;
    const channel = supabase.channel(interviewCallsCountChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "interview_call",
        filter: `team_id=eq.${teamId}`,
      },
      async () => setCount(await getInterviewCallCount(teamId))
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "interview_call",
        filter: `team_id=eq.${teamId}`,
      },
      async () => setCount(await getInterviewCallCount(teamId))
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        setCount(await getInterviewCallCount(teamId));
      }
    });
  }, [teamId, isChannelHealthy, count]);

  // we check that the value is a undefined, since 0 is falsy in javascript
  if (typeof count !== "undefined") {
    return { count, isLoaded: true };
  } else {
    return { count: undefined, isLoaded: false };
  }
};

export type TeamInterviewStats = {
  teamName: string;
  email: string;
  paymentSetup: boolean;
  numInterviews: number;
  hoursSinceCreatedAt: number;
};

type TeamInterviewStatsDb = {
  team_name: string;
  email: string;
  payment_setup: boolean;
  num_interviews: number;
  hours_since_created_at: number;
};

export const getTeamInterviewStats = async (): Promise<
  TeamInterviewStats[]
> => {
  const { data, error } = await supabase.rpc("get_team_interview_stats");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((team: TeamInterviewStatsDb) => ({
    teamName: team.team_name,
    email: team.email,
    paymentSetup: team.payment_setup,
    numInterviews: team.num_interviews,
    hoursSinceCreatedAt: team.hours_since_created_at,
  }));
};
