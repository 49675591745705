import { SerializedStyles, css } from "@emotion/react";

import { uiGray } from "constants/colors";

const style = {
  divider: css({
    margin: "24px 0",
    border: "none",
    borderBottom: `1px solid ${uiGray[10]}`,
  }),
};

export const Divider = ({ customCss }: { customCss?: SerializedStyles }) => {
  return <hr data-testid="divider" css={[style.divider, customCss]} />;
};
